<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <XzTagGroup :data="statusWithAll" :active="activeStatus" @handleClick="handleStatusChange">
          <span class="title">合同状态</span>
        </XzTagGroup>
        <div class="table-wrapper" :class="{'fullscreen': isFullScreen}">
          <xz-table ref="xzTable" :columns="columnsLists" :data="data" :searchAttrs="searchAttrs" :searchVal.sync="form.name"
            :toolsAttrs="toolsAttrs" :drawerVisible.sync="drawerVisible" :scroll="{ x: '100%', y: scrollHeight }" :drawerWidth="460"
            @search="handleNameSearch" rowKey="id" @refresh="handleReset" @fullScreen="handleFullScreen" class="wrap">
            <template #buttons v-if="!isFullScreen">
              <a-button v-if="isJoin || isDirectlySalesCompany" type="primary" icon="plus" v-auth="'contract:list:add'" @click="newContract">
                新增
              </a-button>
              <a-button @click="handleExport">导出</a-button>
            </template>
            <template v-if="!isDirectlySalesCompany" #others>
              <a-radio-group v-model="saleContractType" buttonStyle="solid" @change="handleRadioChange">
                <a-radio-button v-if="isJoin" :class="{ 'active-radio': saleContractType === 'saleContract' }" value="saleContract">
                  加盟销售
                </a-radio-button>
                <a-radio-button :class="{ 'active-radio': saleContractType === 'purchaseContract' }"
                  value="purchaseContract">
                  直营销售
                </a-radio-button>
              </a-radio-group>
            </template>
            <template #drawer>
              <div style="flex: 1; overflow: auto" class="advanced-search">
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                  <a-form-model-item label="合同编号">
                    <a-input v-model="form.code" placeholder="请输入合同编号" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="业务类型">
                    <a-select v-model="form.businessType" placeholder="请选择业务类型" allow-clear>
                      <a-select-option v-for="opt in BusinessTypeModelEnum" :key="opt.value" :value="opt.value">
                        {{ opt.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="合同状态">
                    <a-select v-model="form.executionStatus" placeholder="请选择合同状态" allow-clear>
                      <a-select-option v-for="opt in statusWithAll" :key="opt.value" :value="opt.value">
                        {{ opt.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="最终用户" allow-clear>
                    <a-input v-model="form.finalCustomerName" placeholder="请输入最终用户" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="合同主题">
                    <a-input v-model="form.name" placeholder="请输入合同主题" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="合同金额">
                    <a-input-number v-model="form.totalAmountMin" placeholder="最小金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                    ~
                    <a-input-number v-model="form.totalAmountMax" placeholder="最大金额" :min="0" :step="0.01"
                      style="width: 47%;" :precision="2" />
                  </a-form-model-item>
                  <a-form-model-item label="生效日期">
                    <a-range-picker v-model="form.effectiveDateRange" :placeholder="['开始日期', '结束日期']" valueFormat="x" style="width: 100%;" allow-clear>
                    </a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="到期日期">
                    <a-range-picker v-model="form.contractDateRange" :placeholder="['开始日期', '结束日期']"  valueFormat="x" style="width: 100%;" allow-clear>
                    </a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="业务员">
                    <a-input v-model="form.salesmanName" placeholder="请输入业务员" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="签订日期">
                    <a-range-picker v-model="form.signedDateRange" :placeholder="['开始日期', '结束日期']" valueFormat="x" style="width: 100%;" allow-clear>
                    </a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="制单人">
                    <a-input v-model="form.createUserName" placeholder="请输入制单人" allow-clear />
                  </a-form-model-item>
                  <a-form-model-item label="制单时间">
                    <a-range-picker v-model="form.createDateRange" :placeholder="['开始时间', '结束时间']" valueFormat="x" style="width: 100%;" show-time
                      allow-clear>
                    </a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="客户抬头">
                    <a-input v-model="form.customerHead" placeholder="请输入客户抬头" allow-clear />
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div style="text-align: right; height: 68px; line-height: 68px">
                <a-button @click="handleReset">
                  重置
                </a-button>
                <a-button type="primary" style="margin-left: 10px;" @click="handleAdvancedQuery">
                  确定
                </a-button>
              </div>
            </template>
            <template #custom>
              <el-popover
                placement="left"
                width="300"
                :tabindex="10000"
                popperClass="JZPopper"
                v-model="showAttribute"
                trigger="manual">
                <div class="top"><span>字段设置</span><time @click="showAttribute=false">&times;</time></div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists&&lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox v-model="item.isShow" :disabled="item.isDisabled" style="margin-top:6px;"></el-checkbox><div class="labelContainer">{{item.fieldName}}</div>
                      <i :class="{'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular}" style="font-size: 12px;margin-top:10px;margin-left:12px;" @click="handleClickLock(item, index)"></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">
                      锁定下列项目到此固定
                    </li>
                  </template>
                </draggable>
                <h3 style="border-top: 1px solid #DFE2E8;font-size:14px;height:40px;line-height:40px;margin-bottom:0;">不固定</h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox v-model="item.isShow" :disabled="item.isDisabled" style="margin-top:6px;"></el-checkbox><div class="labelContainer">{{item.fieldName}}</div>
                    <i :class="{'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular}" style="font-size: 12px;margin-top:10px;margin-left:12px;" @click="handleClickLock(item, index)"></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button slot="reference" @click='handleSort' style='width:32px;height:32px;margin-left:8px;padding: 8px;'><img :src="require('@/assets/ic_setting.svg')" style='width:16px;height:16px;font-size:16px;' /></el-button>
              </el-popover>
            </template>
            <span slot="code" slot-scope="text, record">
              <a class="code" v-if="actionPermissionMap['contract:list:detail']" @click="handleView(record)">{{ text }}</a>
              <span v-else>{{ text }}</span>
            </span>
            <span slot="executionStatus" slot-scope="text, record" :class="`status-${record.executionStatus}`">
              {{ EXECUTION_STATUS_ARRAY_MAP[record.executionStatus] }}
            </span>
            <span slot="action" slot-scope="text, record">
              <a v-if="[SALE_CONTRACT_STATUS_DRAFT, SALE_CONTRACT_STATUS_DENIED, SUPPLIER_DENIED].includes(record.executionStatus)" v-auth="'contract:list:update'"
                @click="handleEdit(record)">编辑</a>
              <a v-if="[SALE_CONTRACT_STATUS_IN_PROGRESS].includes(record.executionStatus) && record.unOrderAmount" v-auth="'contract:list:open'"
                @click="newOrder(record)">开单</a>
              <a-divider v-if="[SALE_CONTRACT_STATUS_DENIED, SUPPLIER_DENIED].includes(record.executionStatus)" v-auth="'contract:list:cancel'"
                type="vertical" />
              <a-popconfirm v-if="[SALE_CONTRACT_STATUS_DENIED, SUPPLIER_DENIED].includes(record.executionStatus)"
                placement="leftTop" ok-text="确认" cancel-text="取消" v-auth="'contract:list:cancel'" @confirm="cancelContract(record)">
                <template slot="title">
                  确认取消？
                </template>
                <a>取消</a>
              </a-popconfirm>
              <a-divider v-if="[SALE_CONTRACT_STATUS_DRAFT].includes(record.executionStatus)" v-auth="'contract:list:del'" type="vertical" />
              <a-popconfirm v-if="[SALE_CONTRACT_STATUS_DRAFT].includes(record.executionStatus)" placement="leftTop"
                ok-text="确认" cancel-text="取消" v-auth="'contract:list:del'" @confirm="handleDelete(record)">
                <template slot="title">
                  确认删除？
                </template>
                <a style="color:#F56C6C;">删除</a>
              </a-popconfirm>
            </span>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination :current="page.pageNum" :total="total" :show-total="total => `共 ${total} 条`" show-size-changer
        show-quick-jumper :pageSize.sync="page.pageSize" @change="handleNumChange" @showSizeChange="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import { saleContractList, purchaseContractList, deleteSaleAndPurchContract, exportSaleContract, exportPurchContract, cancelSaleContract, disableContract, finishSaleAndPurchContract, getFiledRuleLists, addFiledRule } from '@/api/contract';
import { tenantBaseInfo } from '@/api/company';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '../../../components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import { BusinessTypeModelEnum } from '@/enums/BusinessTypeEnum';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import directlySaleCompanyMixin from '../mixins/directlySaleCompanyMixin';

const COMMON_COLUMNS = [
  {
    title: '合同编号',
    dataIndex: 'code',
    key: 'code',
    scopedSlots: { customRender: 'code' },
    width: 160,
    ellipsis: true,
  },
  {
    title: '业务类型',
    dataIndex: 'businessTypeDesc',
    key: 'businessTypeDesc',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '合同状态',
    dataIndex: 'executionStatus',
    key: 'executionStatus',
    scopedSlots: { customRender: 'executionStatus' },
    width: 100,
    ellipsis: true,
  },
  {
    title: '最终用户',
    dataIndex: 'finalCustomerName',
    key: 'finalCustomerName',
    width: 180,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '合同主题',
    dataIndex: 'name',
    key: 'name',
    width: 280,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '合同金额',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '生效日期',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD') : '--';
    },
  },
  {
    title: '到期日期',
    dataIndex: 'contractDate',
    key: 'contractDate',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD') : '--';
    },
  },
  {
    title: '业务员',
    dataIndex: 'salesmanName',
    key: 'salesmanName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '签订日期',
    dataIndex: 'signedDate',
    key: 'signedDate',
    width: 120,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD') : '--';
    },
  },
  {
    title: '制单人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 100,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '制单时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 180,
    ellipsis: true,
    customRender: (text) => {
      return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
  {
    title: '客户抬头',
    dataIndex: 'customerHead',
    key: 'customerHead',
    width: 200,
    ellipsis: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
];

const SALE_CONTRACT_STATUS_ALL = '';
const SALE_CONTRACT_STATUS_DRAFT = 0; // 草稿
const SALE_CONTRACT_STATUS_UNDER_REVIEW = 1; // 审核中
const SALE_CONTRACT_STATUS_DENIED = 2; // 已驳回
const SALE_CONTRACT_STATUS_IN_PROGRESS = 3; // 执行中
const SALE_CONTRACT_STATUS_FINISHED = 4; // 已完结
const SALE_CONTRACT_STATUS_CANCELED = 5; // 已取消
const SUPPLIER_AUDIT = 6;
const SUPPLIER_DENIED = 7;

const STATUS = [
  {
    label: '草稿',
    value: SALE_CONTRACT_STATUS_DRAFT,
  },
  {
    label: '审核中',
    value: SALE_CONTRACT_STATUS_UNDER_REVIEW,
  },
  {
    label: '执行中',
    value: SALE_CONTRACT_STATUS_IN_PROGRESS,
  },
  {
    label: '已完结',
    value: SALE_CONTRACT_STATUS_FINISHED,
  },
  {
    label: '已驳回',
    value: SALE_CONTRACT_STATUS_DENIED,
  },
  {
    label: '已取消',
    value: SALE_CONTRACT_STATUS_CANCELED,
  },
];

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已完结', '已取消'];

export default {
  name: 'SaleContracts',
  components: {
    xzTable,
    XzTagGroup,
    draggable,
  },
  mixins: [directlySaleCompanyMixin],
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
    columnsLists () {
      return this.saleContractType === 'saleContract' ? this.columns : this.purchaseColumns;
    },
  },
  watch: {
    saleContractType () {
      this.handleReset();
    },
  },
  data () {
    return {
      columns: [...COMMON_COLUMNS, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 150,
        scopedSlots: { customRender: 'action' },
      }],
      purchaseColumns: [...COMMON_COLUMNS],
      SALE_CONTRACT_STATUS_DRAFT,
      SALE_CONTRACT_STATUS_IN_PROGRESS,
      SALE_CONTRACT_STATUS_DENIED,
      SALE_CONTRACT_STATUS_UNDER_REVIEW,
      SUPPLIER_AUDIT,
      SUPPLIER_DENIED,
      data: [],
      form: {
        code: undefined,
        businessType: undefined,
        executionStatus: '',
        finalCustomerName: undefined,
        name: undefined,
        totalAmountMax: undefined,
        totalAmountMin: undefined,
        effectiveDateRange: undefined,
        contractDateRange: undefined,
        salesmanName: undefined,
        signedDateRange: undefined,
        createUserName: undefined,
        createDateRange: undefined,
        customerHead: undefined,
      },
      activeStatus: '',
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入合同主题',
        data: 'name',
      },
      drawerVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      status: STATUS,
      statusWithAll: [
        {
          label: '全部',
          value: SALE_CONTRACT_STATUS_ALL,
        },
        ...STATUS,
      ],
      saleContractType: 'saleContract',
      EXECUTION_STATUS_ARRAY_MAP,
      BusinessTypeModelEnum,
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      isJoin: false,
      scrollHeight: 600,
      tableBody: null,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      fixedLists: [],
      checkList: [],
      lockLists: [],
      checkLockLists: [],
      attrPropLists: [],
      lockPropLists: [],
    };
  },
  methods: {
    handleRadioChange () {
      this.getRuleLists();
      this.showAttribute = false;
    },
    handleExport () {
      let params = {};
      if (this.saleContractType === 'saleContract') {
        params = {
          code: this.form.code,
          businessType: this.form.businessType,
          executionStatus: this.form.executionStatus ? [this.form.executionStatus] : undefined,
          finalCustomerName: this.form.finalCustomerName,
          name: this.form.name,
          totalAmountMax: this.form.totalAmountMax,
          totalAmountMin: this.form.totalAmountMin,
          effectiveDateStart: this.form.effectiveDateRange ? this.form.effectiveDateRange[0] : undefined,
          effectiveDateEnd: this.form.effectiveDateRange ? this.form.effectiveDateRange[1] : undefined,
          contractDateStart: this.form.contractDateRange ? this.form.contractDateRange[0] : undefined,
          contractDateEnd: this.form.contractDateRange ? this.form.contractDateRange[1] : undefined,
          salesmanName: this.form.salesmanName,
          signedDateStart: this.form.signedDateRange ? this.form.signedDateRange[0] : undefined,
          signedDateEnd: this.form.signedDateRange ? this.form.signedDate[1] : undefined,
          createUserName: this.form.createUserName,
          startCreateDate: this.form.createDateRange ? this.form.createDateRange[0] : undefined,
          endCreateDate: this.form.createDateRange ? this.form.createDateRange[1] : undefined,
          customerHead: this.form.customerHead,
        };
      } else if (this.saleContractType === 'purchaseContract') {
        params = {
          pcCode: this.form.code,
          businessType: this.form.businessType,
          state: this.form.executionStatus,
          finalUserName: this.form.finalCustomerName,
          pcName: this.form.name,
          totalAmountMin: this.form.totalAmountMin,
          totalAmountMax: this.form.totalAmountMax,
          effectiveStartDate: this.form.effectiveDateRange ? this.form.effectiveDateRange[0] : undefined,
          effectiveEndDate: this.form.effectiveDateRange ? this.form.effectiveDateRange[1] : undefined,
          dueStartDate: this.form.contractDateRange ? this.form.contractDate[0] : undefined,
          dueEndDate: this.form.contractDateRange ? this.form.contractDate[1] : undefined,
          salesmanName: this.form.salesmanName,
          signStartDate: this.form.signedDateRange ? this.form.signedDateRange[0] : undefined,
          signEndDate: this.form.signedDateRange ? this.form.signedDateRange[1] : undefined,
          createUserName: this.form.createUserName,
          createStartTime: this.form.createDateRange ? this.form.createDateRange[0] : undefined,
          createEndTime: this.form.createDateRange ? this.form.createDateRange[1] : undefined,
          unit: this.form.customerHead,
        };
      }

      for (const k in params) {
        params[k] = this.setValOrUndefined(params[k]);
      }

      const api = this.saleContractType === 'saleContract' ? exportSaleContract : exportPurchContract;

      api({ ...params, pageNum: 1, pageSize: this.total }).then(({ data }) => {
        const downloadData = {
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `${this.saleContractType === 'saleContract' ? '加盟销售合同' : '直营销售合同'}明细表_${Date.now()}`,
        };
        this.download(downloadData);
      });
    },
    download ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    newContract () {
      this.$router.push({
        name: 'SALESMANAGEMENT_SALESCONTRACTS_NEW',
      });
    },
    newOrder (record) {
      this.$router.push({
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
        query: {
          contractId: this.$encode(record.id),
        },
      });
    },
    handleReset () {
      this.activeStatus = '';

      for (const key in this.form) {
        this.form[key] = key === 'executionStatus' ? '' : undefined;
      }

      this.page.pageNum = 1;
      this.page.pageSize = 10;

      this.handleSearch();
    },
    handleAdvancedQuery () {
      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleSizeChange (current, size) {
      this.page.pageSize = size;

      this.handleSearch();
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
    setValOrUndefined (v) {
      return (v === '' || v === null) ? undefined : v;
    },
    setParamStatus (status) {
      const executionStatus = [];

      if (status === SALE_CONTRACT_STATUS_UNDER_REVIEW) {
        executionStatus.push(SALE_CONTRACT_STATUS_UNDER_REVIEW, SUPPLIER_AUDIT);
      } else if (status === SALE_CONTRACT_STATUS_DENIED) {
        executionStatus.push(SALE_CONTRACT_STATUS_DENIED, SUPPLIER_DENIED);
      } else {
        executionStatus.push(status);
      }

      return executionStatus;
    },
    async handleSearch () {
      const api = this.saleContractType === 'saleContract' ? saleContractList : purchaseContractList;

      let params = {};

      this.activeStatus = this.form.executionStatus;

      if (this.saleContractType === 'saleContract') {
        params = {
          code: this.form.code,
          businessType: this.form.businessType,
          executionStatus: (this.form.executionStatus || this.form.executionStatus === 0) ? this.setParamStatus(this.form.executionStatus) : null,
          finalCustomerName: this.form.finalCustomerName,
          name: this.form.name,
          totalAmountMax: this.form.totalAmountMax,
          totalAmountMin: this.form.totalAmountMin,
          effectiveDateStart: this.form.effectiveDateRange ? this.form.effectiveDateRange[0] : undefined,
          effectiveDateEnd: this.form.effectiveDateRange ? this.form.effectiveDateRange[1] : undefined,
          contractDateStart: this.form.contractDateRange ? this.form.contractDateRange[0] : undefined,
          contractDateEnd: this.form.contractDateRange ? this.form.contractDateRange[1] : undefined,
          salesmanName: this.form.salesmanName,
          signedDateStart: this.form.signedDateRange ? this.form.signedDateRange[0] : undefined,
          signedDateEnd: this.form.signedDateRange ? this.form.signedDate[1] : undefined,
          createUserName: this.form.createUserName,
          startCreateDate: this.form.createDateRange ? this.form.createDateRange[0] : undefined,
          endCreateDate: this.form.createDateRange ? this.form.createDateRange[1] : undefined,
          customerHead: this.form.customerHead,
        };
      } else if (this.saleContractType === 'purchaseContract') {
        params = {
          pcCode: this.form.code,
          businessType: this.form.businessType,
          state: this.form.executionStatus,
          finalUserName: this.form.finalCustomerName,
          pcName: this.form.name,
          totalAmountMin: this.form.totalAmountMin,
          totalAmountMax: this.form.totalAmountMax,
          effectiveStartDate: this.form.effectiveDateRange ? this.form.effectiveDateRange[0] : undefined,
          effectiveEndDate: this.form.effectiveDateRange ? this.form.effectiveDateRange[1] : undefined,
          dueStartDate: this.form.contractDateRange ? this.form.contractDate[0] : undefined,
          dueEndDate: this.form.contractDateRange ? this.form.contractDate[1] : undefined,
          salesmanName: this.form.salesmanName,
          signStartDate: this.form.signedDateRange ? this.form.signedDateRange[0] : undefined,
          signEndDate: this.form.signedDateRange ? this.form.signedDateRange[1] : undefined,
          createUserName: this.form.createUserName,
          createStartTime: this.form.createDateRange ? this.form.createDateRange[0] : undefined,
          createEndTime: this.form.createDateRange ? this.form.createDateRange[1] : undefined,
          unit: this.form.customerHead,
        };
      }

      for (const k in params) {
        params[k] = this.setValOrUndefined(params[k]);
      }
      this.data = [];
      const { body } = await api({
        ...params,
        ...this.page,
      });

      if (this.saleContractType === 'saleContract') {
        this.data = body?.list || [];

        // this.tableBody.style.height = this.data.length ? this.scrollHeight + 'px' : '';
      } else if (this.saleContractType === 'purchaseContract') {
        this.data = (body?.list || []).map(v => ({
          ...v,
          id: v.id,
          code: v.pcCode,
          businessTypeDesc: v.businessTypeDesc,
          executionStatus: v.state,
          finalCustomerName: v.finalCustomerName,
          name: v.pcName,
          totalAmount: v.totalAmount,
          effectiveDate: v.effectiveDate,
          contractDate: v.dueDate,
          salesmanName: v.salesmanName,
          signedDate: v.signDate,
          createUserName: v.createUserName,
          createTime: v.createTime,
          customerHead: v.unit,
        }));
      }

      this.total = body?.total;

      if (this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    handleView (record) {
      this.$router.push({
        name: this.saleContractType === 'saleContract' ? 'SALESMANAGEMENT_SALESCONTRACTS_DETAIL' : 'SALESMANAGEMENT_PURCHCONTRACTS_DETAIL',
        query: {
          id: this.$encode(record.id),
          type: this.saleContractType,
          templateType: record.templateType,
        },
      });
    },
    async handleDelete (record) {
      await deleteSaleAndPurchContract({
        saleContractId: record.id,
      });

      this.handleSearch();
    },
    async cancelContract (record) {
      await cancelSaleContract({
        contractCode: record.code,
      });

      this.handleSearch();
    },
    async handleFinish (record) {
      const res = await finishSaleAndPurchContract({
        saleContractId: record.id,
      });

      if (res.heads.code === 6001) {
        this.$confirm(res.heads.message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await disableContract({id: record.id});

          this.handleSearch();
        });
      } else {
        this.handleSearch();
      }
    },
    handleEdit (record) {
      this.$router.push({
        name: 'SALESMANAGEMENT_SALESCONTRACTS_NEW',
        query: {
          id: this.$encode(record.id),
          type: this.saleContractType,
          businessType: record.businessType,
          templateType: record.templateType,
        },
      });
    },
    handleStatusChange (v) {
      this.activeStatus = v.value;
      this.form.executionStatus = v.value;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    handleNameSearch (v) {
      this.form.name = v;

      this.page.pageNum = 1;

      this.handleSearch();
    },
    async getTenantBaseInfo () {
      if (!this.isDirectlySalesCompany) {
        const { body } = await tenantBaseInfo();

        // 小紫联营服务入驻状态（入驻状态（1-未入驻，2-入驻中，3-已入驻，4-已过期）
        this.isJoin = body.enteringStatus * 1 === 3;

        if (!this.isJoin) {
          this.saleContractType = 'purchaseContract';
        }
      }

      this.handleSearch();
    },
    setAntTableBodyHeight () {
      const tableWrapper = document.querySelector('.xz-table-wrapper');
      const slotWrapper = document.querySelector('.slot-wrapper');
      const thead = document.querySelector('.ant-table-thead');

      this.tableBody = document.querySelector('.ant-table-body');

      this.scrollHeight = tableWrapper.offsetHeight - slotWrapper.offsetHeight - thead.offsetHeight;

      this.getTenantBaseInfo();
    },
    handleFullScreen (flag) {
      this.isFullScreen = flag;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let businessType = {
        'saleContract': '1',
        'purchaseContract': '2',
      }[this.saleContractType];
      let columnLists = JSON.parse(JSON.stringify(COMMON_COLUMNS));
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType,
        isRegular: false,
        isShow: true,
        sort: index + 1,
        isDisabled: ['合同编号', '业务类型'].includes(i.title),
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
      // this.showAttribute = false;
    },
    handleSave () {
      let lists = [
        ...this.lockLists,
        ...this.attrLists,
      ].map((i, index)=>({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        // this.handleSearch();这里需要根据结果 调整展示
        this.getRuleLists(lists);
        this.showAttribute = false;
      });
    },
    // 这里需要处理table
    getColumnsLists (lists) {
      let showIdLists = lists.filter(i => i.isShow).map(i => i.fieldKey);
      let fixedIdLists = lists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);

      let currentLists = COMMON_COLUMNS.filter(i => showIdLists.includes(i.dataIndex)).map(i => ({
        ...i,
        fixed: fixedIdLists.includes(i.dataIndex) ? 'left' : '',
      }));
      let tempLists = [];
      for (let i = 0; i < showIdLists.length; i++) {
        const info = currentLists.find(j => j.key === showIdLists[i]);
        tempLists.push(info);
      }
      let columnLists = tempLists;
      if (this.saleContractType === 'saleContract') {
        columnLists = [...columnLists, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' },
        }];
        this.$nextTick(() => {
          this.columns = columnLists;
        });
      } else if (this.saleContractType === 'purchaseContract') {
        this.$nextTick(() => {
          this.purchaseColumns = columnLists;
        });
      }
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      let businessType = {
        'saleContract': '1',
        'purchaseContract': '2',
      }[this.saleContractType];
      getFiledRuleLists({
        businessType,
      }).then(({body})=>{
        let currentLists = body || [];
        currentLists = JSON.parse(JSON.stringify(currentLists));
        currentLists = currentLists.map(i => ({
          ...i,
          isDisabled: ['合同编号', '业务类型'].includes(i.fieldName),
        }));
        if (currentLists && currentLists.length) {
          this.attrLists = currentLists.filter(i => !i.isRegular);
          this.lockLists = currentLists.filter(i => i.isRegular);
          this.getColumnsLists(currentLists);
        } else {
          let columnLists = [];
          if (this.saleContractType === 'saleContract') {
            columnLists = [...COMMON_COLUMNS, {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              fixed: 'right',
              width: 150,
              scopedSlots: { customRender: 'action' },
            }];
            this.columns = columnLists;
          } else if (this.saleContractType === 'purchaseContract') {
            columnLists = COMMON_COLUMNS;
            this.purchaseColumns = columnLists;
          }
          // 这里需要根据 columnLists 获取需要处理的数据的值
          let lockLists = columnLists.filter(i => i.fixed && i.title !== '操作').map((i, index)=>({
            fieldKey: i.key,
            fieldName: i.title,
            businessType,
            isRegular: false,
            isShow: true,
            sort: index + 1,
            isDisabled: ['合同编号', '业务类型'].includes(i.title),
          }));
          let attrLists = columnLists.filter((i) => !i.fixed).map((i, index) => ({
            fieldKey: i.key,
            fieldName: i.title,
            businessType,
            isRegular: false,
            isShow: true,
            sort: lockLists.length + index + 1,
            isDisabled: ['合同编号', '业务类型'].includes(i.title),
          }));

          this.attrLists = attrLists;
          this.lockLists = lockLists;
        }
        let columnLists = [];
        if (this.saleContractType === 'saleContract') {
          columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' },
          }];
          this.columns = columnLists;
        } else if (this.saleContractType === 'purchaseContract') {
          columnLists = currentLists && currentLists.length ? currentLists : [...COMMON_COLUMNS];
          this.purchaseColumns = columnLists;
        }
        // 这里需要拼接字段属性
        this.attrPropLists = JSON.parse(JSON.stringify(this.attrLists));
        this.lockPropLists = JSON.parse(JSON.stringify(this.lockLists));
      });
    },
  },
  created () {
    this.getRuleLists();
  },
  mounted () {
    this.getTenantBaseInfo();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 16px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-right: 2em;
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #F2F4F7;

          &.active-radio {
            border-color: #E2F3FE;
            background-color: #E2F3FE;
            color: #237FFA;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .code {
          cursor: pointer;
          color: #237FFA;
        }

        .status-0,
        .status-5 {
          @include tag_round();
          color: #1F2733;
          background-color: #F2F4F7;
        }

        .status-1,
        .status-3,
        .status-6 {
          @include tag_round();
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .status-4 {
          @include tag_round();
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .status-2,
        .status-7 {
          @include tag_round();
          background-color: #FEE9E5;
          color: #FF5959;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 100;
  background: #FFFFFF;
}
.text-right{
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}
.top{
  display: flex;
  padding: 6px 0;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #DFE2E8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1F2733 100%;
  letter-spacing: 0px;
  opacity: 1;
  span{
    flex: 1;
    height: 24px;
    line-height: 24px;
  }
  time{
    cursor: pointer;
  }
}
.list-item{
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;
  .el-checkbox__label{
    height: 32px;
    line-height: 32px;

  }

  .labelContainer{
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}
.noFixedItem{
  font-family: PingFang SC;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #FFFFFF;
}
/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
/deep/ .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 5px #C9CED6;
    background: #C9CED6;
}
//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #C9CED6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}
/deep/.ant-drawer-body {
  padding: 16px;
}

</style>

